<template>
  <div class="automentor-app-container">
    <div class="automentor-install-page">
      <div class="automentor-app-header">
        <img alt="Automentor SMS" src="../assets/automentor-logo.png" />
      </div>
      <div v-if="!installation">
        <p v-if="!activeLocation" class="automentor-admin-message">
          Switch into a sub-account and complete the integration
        </p>
        <FormKit type="button" @click="startAuth()"> SETUP </FormKit>
      </div>
      <div v-else class="automentor-settings-wrapper">
        <FormKit type="form" id="automentor-settings-form" form-class="automentor-settings-form"
          submit-label="FINISH SETUP" @submit="saveConfig">

          <FormKit type="text" name="phone" id="phone" label="Phone" help="Set the Phone Number assign to your account"
            placeholder="+1 (555) 555-5555" :value="installation.phone" />
        </FormKit>
      </div>

      <div>
        <!-- Status message moved inside FormKit to appear below the form -->
        <p v-if="statusMessage" :class="['status-message', statusType]" style="margin-top: 10px;">
          {{ statusMessage }}
        </p>
      </div>

    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { GHL } from "../ghl";

export default {
  name: "HelloWorld",
  props: ["activeLocation", "role", "type", "installation"],
  data: () => ({
    submitted: ref(false),
    statusMessage: "", // Holds the message
    statusType: "", // "success" or "error" for styling
  }),
  methods: {
    startAuth() {
      // http://localhost:3000
      const redirectUrl = `${window.location.href}authorize-handler`;
      const scopes = [
        "calendars.readonly",
        "locations.readonly",
        "conversations/message.write",
        "conversations.write",
        "conversations.readonly",
        "contacts.readonly",
        "contacts.write",
        "calendars/events.write",
        "locations/customValues.readonly",
      ].join(" ");
      const appClientId = "667dc8f26c73a780a2f646e9-lxxq04l4";

      window.open(
        `https://marketplace.gohighlevel.com/oauth/chooselocation?response_type=code&loginWindowOpenMode=self&redirect_uri=${redirectUrl}&client_id=${appClientId}&scope=${scopes}`
      );
    },
    // saveConfig(data) {
    //   console.log("submitted items", data);
    //   GHL.getInstance()
    //     .updatePhone(data)
    //     .then((data) => {
    //       console.log("updatePhone", data);
    //     });
    // },
    async saveConfig(data) {
      try {
        const response = await GHL.getInstance().updatePhone(data);
        console.log("updatePhone", response);

        if (response.status === 200) {
          this.statusMessage = "Phone ID updated successfully!";
          this.statusType = "success";
        } else {
          this.statusMessage = "Failed to update Phone ID.";
          this.statusType = "error";
        }
      } catch (error) {
        console.error("Error updating phone:", error);
        this.statusMessage = "Error: Unable to update Phone ID.";
        this.statusType = "error";
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.automentor-app-header {
  margin-bottom: 2rem;
}

.automentor-admin-message {
  font-size: 32px;
  font-weight: 600;
}

.automentor-app-header img {
  width: 100%;
  max-width: 350px;
}

.hide {
  display: none;
}

.automentor-install-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;
  justify-content: center;
  height: 100vh;
}

.automentor-settings-wrapper {
  display: flex;
  justify-content: center;
}

.automentor-settings-form {
  max-width: 380px;
  display: flex;
  flex-direction: column;
  text-align: left;
  background: white;
  padding: 24px;
  box-shadow: 1px 4px 5px rgba(0, 0, 0, 0.3);
  color: black;
}

[data-type="submit"] .formkit-input {
  font-weight: bold;
}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}


/* Status message styling */
.status-message {
  margin-top: 15px;
  font-weight: bold;
  padding: 8px;
  border-radius: 5px;
  width: 100%;
  text-align: center;
}

.success {
  color: #155724;
  background-color: #d4edda;
  border: 1px solid #c3e6cb;
}

.error {
  color: #721c24;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
}
</style>
